<!--
File: Users.vue
Description: show list of users in the DB.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px"
              :placeholder="$t('label.search_records')" v-model="searchQuery"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="userProfile(null)"> {{ $t('buttons.add') }} </md-button>
        </div>
      </div>
    </md-card-header>
    <md-card-content>
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        md-fixed-header :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort">
        <md-table-empty-state md-label="No data to show" :md-description="`Try to select another search criteria.`" />
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.search_again')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('users.email')" md-sort-by="email">{{ item.email }}</md-table-cell>
          <md-table-cell :md-label="$t('users.firstname')" md-sort-by="first_name">
            {{ item.first_name }}
          </md-table-cell>
          <md-table-cell :md-label="$t('users.lastname')" md-sort-by="last_name">{{ item.last_name }}</md-table-cell>
          <md-table-cell :md-label="$t('users.group_id')" md-sort-by="group_id">
            {{ groupById(item.group_id) }}
          </md-table-cell>
          <md-table-cell class="md-display-3" :md-label="$t('tables.actions')">
            <div>
              <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="handleEdit(item)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button class="md-just-icon md-danger md-simple" @click.stop.prevent="handleDelete(item)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="footer-table md-table">
        <table>
          <tfoot>
            <tr>
              <th v-for="item in footerTable" :key="item.name" class="md-table-head">
                <div class="md-table-head-container md-ripple md-disabled">
                  <div class="md-table-head-label">{{ item }}</div>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </md-card-content>
    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>
</template>

<script>
import { Pagination } from '@/components'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import { customSortMixin } from '@/customSortMixin'
import { mapState, mapGetters } from 'vuex'

export default {
  mixins: [customSortMixin],
  name: 'users-list-form',
  data() {
    return {
      currentSort: 'first_name',
      currentSortOrder: 'asc',
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      footerTable: [],
      searchQuery: '',
      propsToSearch: ['email', 'first_name', 'last_name'],
      searchedData: [],
      fuseSearch: null
    }
  },

  components: {
    Pagination
  },

  mounted() {
    this.$store.dispatch('LOAD_GROUP_LIST')
    this.reloadUsersList()
  },

  methods: {
    reloadUsersList() {
      this.$store.dispatch('LOAD_USERS_LIST').then(() => {
        // Fuse search initialization.
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        })
      })
    },
    userProfile() {
      this.$router.push('user_add')
    },
    handleEdit(item) {
      const { id } = item
      this.$router.push(`user_upd/${id}`)
    },
    handleDelete(item) {
      Swal.fire({
        icon: 'warning',
        title: this.$t('modal.are_you_sure'),
        text: this.$t(`modal.the_operation_cannot_be_canceled`),
        showCancelButton: true,
        cancelButtonText: this.$t('buttons.cancel'),
        confirmButtonText: this.$t('modal.yes_delete'),
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('DEL_USER', item.id).then(() => {
            Swal.fire({
              icon: 'success',
              title: this.$t('modal.deleted'),
              text: `You deleted ${item.email}`,
            })
            this.reloadUsersList()
          })
        }
      })
    }
  },

  computed: {
    ...mapState({
      tableData: (state) => state.Users.list
    }),
    ...mapGetters(['groupById']),

    queriedData() {
      const result = !this.searchQuery ? this.tableData : this.searchedData //? this.searchedData : []
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchQuery ? this.searchedData.length : this.tableData.length
    },
  },
  watch: {
    searchQuery(value) {
      if (this.tableData.length == 0) {
        this.searchedData = []
        return
      }
      this.searchedData = (value !== '') ? this.fuseSearch.search(this.searchQuery) : this.tableData
    }
  }
}
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-card {
  margin: 0px 0;
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>